import TYPES from '@/types';

// Application
import {
  GetCreateCustomerKuspitAccountStatusQuery,
} from '@/modules/my-investment/kuspit/customer-kuspit-account/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsCreatingAccountKuspitViewModel {
  @Inject(TYPES.GET_CREATE_CUSTOMER_KUSPIT_ACCOUNT_STATUS_QUERY)
  private readonly getCreateCustomerKuspitAccountStatusQuery!:
    GetCreateCustomerKuspitAccountStatusQuery;

  readonly i18n_namespace = 'components.contract-savings.creating_account.kuspit';

  get status() {
    return this.getCreateCustomerKuspitAccountStatusQuery.execute();
  }

  get accountWasCreated() {
    const last_step = this.status;

    return last_step.percent === 100 && last_step.step_number === 3;
  }
}
